import React from 'react';

import { Button } from 'core/ui';
import { withModals } from 'core/state/Modals';

function OpenModalButton(props) {
  const {
    children,
    modal,
    modalProps,
    showModal,
    ...restProps
  } = props;

  return (
    <Button {...restProps} onClick={() => showModal(modal, modalProps)}>
      {children}
    </Button>
  );
}

export default withModals(OpenModalButton);
