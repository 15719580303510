import React, { useRef } from 'react';
import { Popover } from 'components/Tippy';
import styled, { createGlobalStyle } from 'styled-components';

import Icon from 'core/ui/Icon';

const DropdownTheme = createGlobalStyle`
  .rvshare-dropdown-selectbox-theme {
    &.tippy-tooltip {
      background-color: #fff;
      border: 1px solid ${props => props.theme.borders.lightGrey};
      border-radius: 4px;
      color: #0A2239;
      padding: 0;
    }
  }
`;

const DropdownItem = styled.div`
  cursor: pointer;
  padding: 5px 32px;
  font-size: 14px;
  background-color: ${props => (props.isActive ? props.theme.colors.infoLight : '')};
  position: relative;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: ${props => props.theme.colors.infoLight};
  }

  ${props => props.styles};
`;

const Checkmark = styled(Icon).attrs({ icon: 'check' })`
  color: ${props => props.theme.colors.primaryBlue};
  position: absolute;
  left: 12px;
  top: 9px;
  font-size: 10px;

  ${props => props.styles};
`;

function DropdownOptions(props) {
  const { activeItem, options, onChange, closeTippy, dropdownItemStyles, checkmarkStyles } = props;

  return options.map(option => {
    const isActive = option.value === activeItem;
    const handleChange = () => {
      onChange(option.value);
      closeTippy();
    };

    const handleKeyDown = event => {
      if (event.keyCode === 13) {
        // ENTER key
        onChange(option.value);
        closeTippy();
      }

      if (event.keyCode === 27) {
        closeTippy();
      }
    };

    return (
      <DropdownItem
        key={option.value}
        isActive={isActive}
        onClick={handleChange}
        onKeyDown={handleKeyDown}
        role="button"
        styles={dropdownItemStyles}
      >
        {isActive && <Checkmark styles={checkmarkStyles} />}
        {option.label}
      </DropdownItem>
    );
  });
}

export default function DropdownSelectbox({
  options,
  activeItem,
  children,
  onChange,
  handleToggled,
  popoverOptions,
  dropdownItemStyles,
  checkmarkStyles,
}) {
  const tippy = useRef(null);

  const onCreate = instance => {
    tippy.current = instance;
  };

  const closeTippy = () => {
    tippy.current.hide();
  };

  const onHide = () => {
    if (handleToggled) {
      handleToggled(false);
    }
  };

  const onShow = () => {
    if (handleToggled) {
      handleToggled(true);
    }
  };

  const dropdownOptions = (
    <DropdownOptions
      activeItem={activeItem}
      options={options}
      onChange={onChange}
      closeTippy={closeTippy}
      dropdownItemStyles={dropdownItemStyles}
      checkmarkStyles={checkmarkStyles}
    />
  );

  return (
    <>
      <DropdownTheme />
      <Popover
        theme="rvshare-dropdown-selectbox"
        placement="bottom-start"
        {...popoverOptions}
        onCreate={onCreate}
        content={dropdownOptions}
        onShow={onShow}
        onHide={onHide}
      >
        {children}
      </Popover>
    </>
  );
}
