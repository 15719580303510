/* eslint-disable global-require */
import styled, { css, keyframes } from 'styled-components';

import OpenModalButton from 'components/OpenModalButton';
import { Box, Flex } from 'core/ui';

import DestinationInput from '../../SearchFields/DestinationInput';

const underline = props => keyframes`
  from {
    border-bottom-width: 1px;
    border-bottom-color: ${props.theme.colors.lightGrey};
  }

  to {
    border-bottom-width: 1px;
    border-bottom-color: ${props.theme.colors.primaryBlue};
  }
`;

export const DestinationWrapper = styled.div`
  border: 1px solid
    ${props => (props.focused ? props.theme.colors.primaryBlue : props.theme.borders.lightGrey)};
  border-radius: 30px;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 40px;
  ${props =>
    props.animate &&
    css`
      animation: ${underline} 0.15s 0.2s ease-in-out forwards;
    `}
`;

export const SearchIconWrapper = styled.div`
  align-items: flex-end;
  color: ${props => props.theme.colors.primaryBlue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 22px;
`;

export const InputWrapper = styled.div`
  flex: 1;
  color: ${props => props.theme.colors.primaryBlue};
  font-size: 14px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  vertical-align: middle;

  .search-inputs__destination-input {
    input {
      -webkit-appearance: none;
      color: ${props => props.theme.colors.primaryBlue};
      padding-left: 0;
      font-weight: 600;
      font-size: 16px;
      padding: 11px;

      &::-webkit-input-placeholder {
        color: ${props => props.theme.colors.primaryBlue};
      }

      &::-moz-placeholder {
        color: ${props => props.theme.colors.primaryBlue};
      }

      &:-ms-input-placeholder {
        color: ${props => props.theme.colors.primaryBlue};
      }

      &:-moz-placeholder {
        color: ${props => props.theme.colors.primaryBlue};
      }

      :focus {
        outline: none;
      }
    }
  }
`;

export const DatePicker = styled.div`
  position: relative;
  && {
    .DateRangePicker {
      width: 100%;

      ${({ theme }) => theme.medium`
        width: auto;
      `};
    }

    .DateRangePicker_picker {
      z-index: 5;
      /* used to center the date picker */
      transform: translateX(-50%);
      left: 50%;
      right: auto !important;
    }

    .DateRangePickerInput {
      display: flex;
      border: none;
      border-radius: 4px;
      width: 100%;

      ${({ theme }) => theme.medium`
        width: auto;
      `};
    }

    .DateRangePickerInput_calendarIcon {
      margin-left: 0;
      margin-right: 0;
    }

    .DateInput {
      width: 50%;
      height: 40px;

      &:first-of-type {
        border: 1px solid ${props => props.theme.borders.lightGrey};
        border-radius: 30px 0 0 30px;
        overflow: hidden;
        border-right: none;
        &::before {
          content: '';
          width: 15px;
          height: 15px;
          position: absolute;
          left: 8px;
          top: 12px;
          background: url(${require('images/icons/calendar-alt-regular-blue.svg')}) no-repeat;
          background-size: contain;
        }
      }

      &:not(:first-of-type) {
        border: 1px solid ${props => props.theme.borders.lightGrey};
        border-radius: 0 30px 30px 0;
        overflow: hidden;
        border-left: none;

        .DateInput_input {
          padding-left: 16px;
        }
      }

      ${({ theme }) => theme.medium`
        min-width: 85px;
        max-width: 125px;
      `}
    }

    .DateInput_input {
      border-radius: inherit;
      padding: 7px 12px;
      padding-left: 30px;
      height: 40px;
    }

    .SearchBar__dates {
      margin: 0;
    }
  }

  input {
    border-radius: 30px;
    color: ${props => props.theme.colors.primaryBlue};
    font-size: 16px;
    font-weight: 600;

    &::-webkit-input-placeholder {
      color: ${props => props.theme.colors.primaryBlue};
    }

    &::-moz-placeholder {
      color: ${props => props.theme.colors.primaryBlue};
    }

    &:-ms-input-placeholder {
      color: ${props => props.theme.colors.primaryBlue};
    }

    &:-moz-placeholder {
      color: ${props => props.theme.colors.primaryBlue};
    }
  }
`;

export const DateInputDivider = styled.span`
  border-right: 1px solid ${props => props.theme.borders.lightGrey};
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
`;

export const BoxDestination = styled(Box)`
  flex: 1;
  position: relative;

  ${({ theme }) => theme.medium`
    min-width: 150px;
    max-width: 200px;
    flex: auto;
    flex-basis: auto;
  `}
`;

export const SearchDestinationInput = styled(DestinationInput)`
  .SearchBar__location__AutocompleteContainer {
    border-top: none;
    border: 1px solid ${props => props.theme.borders.lightGrey};
    top: 90%;
    left: 0;
  }
`;

export const MobileDateTrigger = styled(OpenModalButton)`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;

  ${({ theme }) => theme.medium`
    display: none;
  `}
`;

export const SearchInputsFlexWrap = styled(Flex)`
  flex-wrap: wrap;

  ${({ theme }) => theme.medium`
    flex-wrap: nowrap;
    align-items: center;
  `}
`;

export const DatePickerWrapper = styled(Box)`
  flex: 1;
  flex-basis: 100%;
  margin-left: 0;
  margin-top: 10px;
  order: 2;

  ${({ theme }) => theme.medium`
    flex: auto;
    flex-basis: auto;
    margin-left: 6px;
    margin-top: 0;
    order: 0;
  `}
`;

export const TravelerSelectWrapper = styled(Box)`
  order: 1;
  height: 40px;

  ${({ theme }) => theme.medium`
    order: 0;
  `}
`;

const showSearchText = css`
  .search-btn-text {
    display: inline;
  }

  .search-btn-icon {
    display: none;
  }
`;

const showSearchIcon = css`
  .search-btn-text {
    display: none;
  }

  .search-btn-icon {
    display: inline;
  }
`;

const displayUnlessSubmitOnChange = ({ submitOnChange }) =>
  css`
    display: ${submitOnChange ? 'none' : 'block'};
  `;

export const SearchButtonWrapper = styled(Box)`
  order: 3;
  margin-top: 10px;
  margin-left: 10px;

  button {
    padding: 12px 14px;
    min-height: 40px;
    height: auto;
  }

  ${displayUnlessSubmitOnChange};
  ${showSearchIcon};

  ${({ theme }) => theme.small`

    ${showSearchText};
    ${displayUnlessSubmitOnChange};
  `}

  ${({ theme }) => theme.medium`
    button {
      padding: 0;
      width: 40px;
    }
    margin-top: 0;
    ${displayUnlessSubmitOnChange};
    ${showSearchIcon};
  `}

  @media (min-width: 1060px) {
    button {
      padding: 12px 14px;
      width: auto;
    }
    ${showSearchText};
  }
`;
