import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import cx from 'classnames';
import range from 'array-range';

import Icon from 'core/ui/Icon';
import DropdownSelectbox from 'components/DropdownSelectbox';

const TRAVELER_SELECT_BREAKPOINT = '1145px';

const DropdownButton = styled.div`
  background-color: #fff;
  border: 1px solid lightgrey;
`;

const whiteText = css`
  color: #fff;
`;

const primaryBlueText = css`
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

const darkBlueText = css`
  color: ${({ theme }) => theme.colors.darkBlue};
`;

const mutedGreyText = css`
  color: ${({ theme }) => theme.colors.mutedGrey};
`;

function travelerOptions() {
  const numbers = range(6);

  return numbers.map(index => {
    const number = index + 1;
    let label = number;
    if (numbers.length === number) {
      label = `${number}+`;
    }

    return {
      label,
      value: number,
    };
  });
}

function TravelerSelect({ className, value, onChange, hideLabel, height = '54px' }) {
  const [selectedItem, setSelected] = useState(value);
  const [isExpanded, setIsExpanded] = useState(false);

  function handleChangeValue(newValue) {
    setSelected(newValue);
    onChange('numberOfTravelers', newValue);
  }

  function handleToggled(visible) {
    setIsExpanded(visible);
  }

  // Allow updates from outside component
  React.useEffect(() => {
    if (value !== selectedItem) {
      setSelected(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div height={height} className={cx(className, { open: isExpanded })}>
      <DropdownSelectbox
        options={travelerOptions()}
        activeItem={selectedItem}
        onChange={handleChangeValue}
        handleToggled={handleToggled}
      >
        <DropdownButton
          className="Button"
          type="button"
          aria-haspopup="true"
          aria-expanded={!!isExpanded}
          data-id="traveler-select"
        >
          <span className="IconAndLabel">
            <Icon className="TravelersIcon" icon="user-friends" />

            <span className={cx('InputLabel', { 'sr-only': hideLabel })}>Guests:</span>

            <span className="SelectedItemText">
              {selectedItem}
              {selectedItem === 6 ? '+' : ''}
            </span>
          </span>

          <Icon className="ArrowIcon" icon={isExpanded ? 'angle-up' : 'angle-down'} />
        </DropdownButton>
      </DropdownSelectbox>
    </div>
  );
}

export default styled(TravelerSelect)`
  height: ${props => props.height};

  .SelectedItemText {
    ${darkBlueText};
    font-size: 16px;
    margin-left: 8px;
    margin-right: 10px;
  }

  .ArrowIcon {
    ${primaryBlueText};
    vertical-align: bottom;
    font-size: 20px;
  }

  .IconAndLabel {
    white-space: nowrap;
    margin-top: 0;
  }

  .GuestsIcon {
    ${mutedGreyText};
  }

  .InputLabel {
    ${darkBlueText};
    font-size: 1rem;
    font-weight: normal;
    position: absolute;
    top: 4px;
    left: 4px;
    visibility: hidden;

    @media (min-width: ${props => (props.inputLarge ? '0px' : TRAVELER_SELECT_BREAKPOINT)}) {
      font-size: 16px;
      left: auto;
      margin-left: 8px;
      position: relative;
      top: auto;
      visibility: inherit;
    }
  }

  .Button {
    -webkit-appearance: none;
    padding-top: 11px;
    padding-left: 8px;
    padding-right: 10px;
    padding-bottom: 5px;
    position: relative;
    display: flex;
    height: 100%;
    border-radius: 30px;
    padding-bottom: 11px;
  }

  &.open {
    .Button {
      background-color: ${({ theme }) => theme.colors.primaryBlue};
    }

    svg {
      color: white;
    }

    .GuestsIcon,
    .SelectedItemText,
    .ArrowIcon,
    .SvgInner,
    .InputLabel {
      ${whiteText};
    }
  }
`;
